import { useTranslation } from "react-i18next";
import React from "react";
import { goToNewPage } from "../functions/Globalfunctions";
import { Icon } from "../icon/Icon";
import { Label } from "../label/Label";

export type AppProps = {

	/**
	 * React key
	 */
	key?: string;

	/**
	 * Contains data needed to build up the Apps component
	 */
	appData?: any;
};

// Apps returns <div> with properties of relevent app
export const App = (props: AppProps): JSX.Element => {
	const app = props.appData;
	const { t } = useTranslation();
	
	return (
		<div className="card">
			<div className="card-label">
				<div className="label-wrap">
					<Label />
				</div>
				<div className="label-content">
					<Icon size="18px" icon="map" className="icon" />
					<span>{app.appType.name}</span>
				</div>
			</div>
			<div role="none" className="card-image">
				{app.thumbnail ? 
				<img loading="lazy" src={`${app.thumbnail}?width=700&height=350&mode=crop`} /> :
				<img src="~/dist/resources/images/map-background.svg" />
				}
			</div>
			<div className="card-bottom">
				<div className="card-details">
					<div className="card-title flex gap-10">
						<Icon size="18px" icon="map" className="icon" />
						<h2>{app.name}</h2>
					</div>
					<div className="card-description-buttons">
						<div className="app-description">{app.description}</div>
						<div className="card-buttons">
							<button
								className="button button-primary button-icon"
								onClick={() => goToNewPage(app.url)}
							>
								<Icon size="16" icon="eye" className="icon" />
								{t("VIEW")}
							</button>
							{app.editUrl && <button
								className="button button-secondary button-icon button-edit"
								onClick={() => goToNewPage(app.editUrl)}
							>
								<Icon size="16" icon="gear" className="icon" />
								{t("EDIT")}
							</button>}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

import { useGeoApps } from "@mapgear/geoapps-ui-framework";
import { useEffect, useState } from 'react';

/**
 * Hook to check if current user is admin
 */
export const useCheckIfAdmin = () => {
    const { geoapps } = useGeoApps();
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    // Check if user is an admin
    useEffect(() => {
        geoapps.onAuthenticationChangedEvent.add(() => {
            if (geoapps.CurrentUser.roles.includes("Admin")) {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
            }
        });
    }, [])

    return {
        isAdmin
    }
};

import React, { useEffect, useState } from "react";
import { Header } from "./Header";
import { AppTable } from "./AppTable";
import { FilterProps } from "../types/FilterProps";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";

export const AppLayout = (): JSX.Element => {
    const { i18n } = useTranslation();
    const auth = useAuth();
    
    /**
     * Declare useState for various filter parameters
     */
    const [filterParam, setFilterParam] = useState<FilterProps>({
        search: "",
        groupBy: "project.name"
    });

    // Set correct language based on user profile
    useEffect(() => {
        if (!auth.user?.profile)
            return;

        const profile = auth.user.profile as any;
        i18n.changeLanguage(profile.language);

    }, [auth.user?.profile]);

    return (
        <div className="maincontainer">
            <Header setFilterParam={setFilterParam} />
            <AppTable filterParam={filterParam} />
        </div>
    )
};
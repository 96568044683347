import React from "react";
import { goToNewPage } from "../functions/Globalfunctions";
import { useAuth } from "react-oidc-context";
import { useGeoApps } from "@mapgear/geoapps-ui-framework";
import { useCheckIfAdmin } from "../hooks/useCheckIfAdmin";
import { useTranslation } from 'react-i18next';

export type ShowProps = {
	/**
	 * Whether the login window is shown or hidden
	 */
	show: boolean;
};

export const LoginOptionWindow = (props: ShowProps): JSX.Element => {
	const { isAdmin } = useCheckIfAdmin();
	const { t } = useTranslation();

	const auth = useAuth();
	const profile = auth.user?.profile;

	const { geoapps } = useGeoApps();
	const tenantUrl: string = geoapps.TenantUrl


	// Sign out
	const signOut = () => {
		auth.signoutRedirect();
	};

	return props.show ? (
		<div className="currentUserWindow">
			<div className="top">
				<div className="user-info">
					<img className="user-image" src={profile?.picture} />
					<div className="user-details">
						<div className="user-name">{`${profile?.given_name} ${profile?.family_name}`}</div>
						<div className="user-email">{profile?.email}</div>
						<div className="user-links">
							<button onClick={() => goToNewPage(profile?.pwd_url as string)}>
								{t("CHANGE_PASSWORD")}
							</button>
							<button onClick={() => goToNewPage(profile?.tenant_url as string)}>
								{t('MY_ENVIRONMENTS', { ns: "overview" })}
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="bottom">
				{isAdmin && (
					<>
						<button onClick={() => goToNewPage("/manage")}>
							{t('MANAGE', { ns: "common" })}
						</button>
						<button onClick={() => goToNewPage(`https://store.geoapps.cloud/?tenanturl=https://${tenantUrl}`)}>
							{t("GEOAPPS_STORE")}
						</button>
					</>
				)}
				<button className="logout" onClick={signOut}>
					{t('LOGOUT', { ns: "common" })}
				</button>
			</div>
		</div>
	) : (
		<></>
	);
};

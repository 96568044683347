import { useTranslation } from "react-i18next";
import isHotkey from "is-hotkey";
import React, { useEffect, useRef, useState } from "react";
import { Icon } from "../icon/Icon";
import { CreateAppList } from "./CreateAppList";

export const CreateApp = (): JSX.Element => {
  const [display, setDisplay] = useState<boolean>(false);
  const [icon, setIcon] = useState<string>("chevron-down");
  const createRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener("keydown", onKeydown);
    document.addEventListener("click", onDocumentClick);

    return () => {
      window.removeEventListener("keydown", onKeydown);
      document.removeEventListener("click", onDocumentClick);
    }
  }, []);

  /**
   * Track keydown
   * @param e - Event of the keydown
   */
  const onKeydown = (e: KeyboardEvent) => {
    if ((e.target as HTMLElement).localName !== "body")
      return;

    // Open new app dialog
    if (isHotkey(["n"], e)) {
      e.preventDefault();
      updateDisplay(true);
    }

    // Close new app dialog
    if (isHotkey(["esc"], e)) {
      updateDisplay(false);
    }
  };

  /**
   * Show the CreateAppList
   * Set dropdown icon; placeholder for now
   * ToDo: Replace with correct icon
   */
  const toggleDisplay = () => {
    updateDisplay(!display);
  };

  /**
   * Update UI state of the CreateApp modal
   * @param enable 
   */
  const updateDisplay = (enable: boolean) => {
    setDisplay(enable);
    setIcon(enable ? "chevron-up" : "chevron-down");
  }

  /**
   * Detects click outside of create app button
   * Hides CreateAppsList
   */
  const onDocumentClick = (e: MouseEvent) => {
    if (createRef.current && !createRef.current?.contains((e.target) as Node)) {
      updateDisplay(false);
    }
  };

  return (
    <>
      <div className="create-app">
        <button title="Nieuwe App"
          className="button button-primary button-icon"
          ref={createRef}
          onClick={toggleDisplay}
        >
          <span className="title">{t("NEW_APP")}</span>
          <Icon size="12px" icon="plus" className="icon plus" />
          <Icon size="12px" icon={icon} className="icon dropdown" />
        </button>
        {display && <CreateAppList />}
      </div>
    </>
  )
};
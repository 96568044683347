import React, { useState, useEffect, useRef } from "react";
import { LoginOptionWindow } from "./LoginOptionWindow";
import { Icon } from "../icon/Icon";
import { useAuth } from "react-oidc-context";
import { OidcUserStateType } from "../../types/OidcUserStateType";

export const User = (): JSX.Element => {
    const auth = useAuth();
    const [display, setDisplay] = useState<boolean>(false);
    const profileRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Hide LoginOptionWindow on click outside of profile image
        document.addEventListener("click", detectOutsideClick);

        return () => {
            document.removeEventListener("click", detectOutsideClick);
        };
    }, []);

    /**
     * Detects click outside of profile image
     * Hides LoginOptionWindow
     */
    const detectOutsideClick = (e: MouseEvent) => {
        if (profileRef.current && !profileRef.current?.contains((e.target) as Node)) {
            setDisplay(false);
        }
    };

    /**
     * Show the Login window on profile image click
     */
    const toggleDisplay = () => {
        setDisplay(!display);
    };

    const signIn = () => {
        // Store the active href
        const state: OidcUserStateType = {
            href: window.location.href,
        };

        // Let the user sign in
        auth.signinRedirect({ state: state });
    };

    return (
        auth.isAuthenticated ? (
            <>
                <div role="none" ref={profileRef} className="profileImg" onClick={toggleDisplay}>
                    <img className="setProfileImg" src={auth.user?.profile.picture} />
                </div>
                <LoginOptionWindow show={display} />
            </>)
            : (<>
                <button className="user-icon-button" onClick={() => signIn()}>
                    <Icon size="40px" icon="user-icon" className="user-icon" />
                </button>
            </>
            )
    );
};

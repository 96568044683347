import React, { Dispatch, SetStateAction } from "react";
import { useCheckIfAdmin } from "./hooks/useCheckIfAdmin";
import { FilterProps } from "../types/FilterProps";
import { CreateApp } from "./header/CreateApp";
import { GroupBy } from "./header/GroupBy";
import { Search } from "./header/Search";
import { User } from "./login/User";

export type HeaderProps = {
	/**
	 * Filter parameter State setter
	 */
	setFilterParam: Dispatch<SetStateAction<FilterProps>>;
};

export const Header = (props: HeaderProps): JSX.Element => {
	const { isAdmin } = useCheckIfAdmin();

	return (
		<div className="header">
			<div className="container">
				<div className="logo"></div>
				<div className="filterbar">
					<Search setSearchParam={props.setFilterParam} />
					<GroupBy setGroupByParam={props.setFilterParam} />
				</div>
				{isAdmin && <CreateApp />}
				<div className="user">
					<User />
				</div>
			</div>
		</div>
	);
};

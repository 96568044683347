import React, { useState, useEffect } from "react";
import { Group } from "./apps/Group";
import { LoadingIndicator } from "./LoadingIndicator";
import { useGeoApps } from "@mapgear/geoapps-ui-framework";
import { LoadingType } from "../types/LoadingType";
import { FilterProps } from "../types/FilterProps";
import { useTranslation } from "react-i18next";

export type AppTableProps = {
    /**
     * Filter state
     */
    filterParam: FilterProps;
};

export const AppTable = (props: AppTableProps): JSX.Element => {
    const { geoapps } = useGeoApps();
    const { t } = useTranslation();
    const [init, setInit] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingFailed, setLoadingFailed] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);

    // Get apps on initial load
    useEffect(() => {
        getApps();
    }, []);

    // Get apps on props change
    useEffect(() => {
        if (init) {
            getApps();
        }
    }, [props]);

    /**
     * Download apps from the server
     */
    const getApps = () => {
        setLoading(true);

        geoapps.Apps.GetList({ direction: 'asc', search: props.filterParam.search, groupBy: props.filterParam.groupBy, orderBy: "Name" }).then(data => {
            setData(data.groups);
            setLoading(false);

            if (!init) {
                setInit(true);
            }
        })
            .catch(error => () => {
                console.error(error);
                setLoadingFailed(true);
            });
    }

    /**
     * Get rendered list of app groups
     */
    const groups = data.map((apps, index) => (
        <Group key={`g${index}`} groupIdx={index} groupName={apps.identifier} appData={apps} />
    ));

    /**
     * Determine what to render; whether page is busy loading, the loading has failed, 
     * no apps were loaded at all or the page is done loading and returns the apps/groups.
     */
    const getContent = () => {
        if (loading) {
            return <LoadingIndicator indicator={LoadingType.LOADING} text={t("APPS_LOADING")} />;
        } else if (loadingFailed) {
            return <LoadingIndicator indicator={LoadingType.ERROR} text={t("APPS_ERROR")} />;
        } else if (data.length === 0) {
            return <LoadingIndicator indicator={LoadingType.ERROR} text={t("NO_APPS")} />;
        } else {
            return groups;
        }
    }

    return (
        <div className="app-overview">
            <div className="container">
                {getContent()}
            </div>
        </div>
    );
};

/**
 * Redirects to a new page on a different window
 * @param url URL of the window being redirected to
 */
export const goToNewPage = (url: string) => {
    window.open(url, "_blank");
};

/**
 * Redirects to a new page on a different window (with new session)
 * @param url URL of the window being redirected to
 */
export const goToNewPageSafe = (url: string) => {
    // Create anchor element
    const anchorElement = document.createElement("a");
    anchorElement.href = url;
    anchorElement.target = "_blank";

    // Append to the body so it can be programmaticly clicked and remove it after
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
};

/**
 * Redirects to a new page on the same window
 * @param url URL of the window being redirected to
 */
export const changeCurrentPage = (url: string) => {
    window.location.replace(url);
};

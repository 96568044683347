import * as React from "react";
export const Label = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 143 64.401" preserveAspectRatio="none">
      <defs>
        <filter
          id="a"
          x="0"
          y="0"
          width="143"
          height="64.401"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="2.5" result="blur" />
          <feFlood floodOpacity=".263" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g filter="url(#a)">
        <path
          data-name="Path 446"
          d="M135.5 2.5v39.985L71.435 51.9 7.5 42.485V2.5Z"
          fill="#8cc22c"
          className="path"
        />
      </g>
    </svg>
  );
};
